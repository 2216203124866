var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "mb-4" }, [
          _c("span", { staticClass: "secondary--text font-weight-bold" }, [
            _vm._v("New " + _vm._s(_vm.selectedSpaceInfo.type)),
          ]),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "div",
                [
                  _c("v-text-field", {
                    attrs: {
                      label: `${_vm.selectedSpaceInfo.type} name*`,
                      counter: 50,
                      rules: [_vm.rules.nonEmpty],
                      required: "",
                      disabled: _vm.loading || _vm.fetchingSpaces,
                      autofocus: "",
                      "hide-details": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.spaceName,
                      callback: function ($$v) {
                        _vm.spaceName = $$v
                      },
                      expression: "spaceName",
                    },
                  }),
                  _c("ShortIdInput", {
                    attrs: {
                      disabledEdit: _vm.loading || _vm.fetchingSpaces,
                      longId: _vm.spaceName,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.updateShortId($event.nextValue)
                      },
                    },
                  }),
                ],
                1
              ),
              _c("v-textarea", {
                staticClass: "mt-5",
                attrs: {
                  label: "Short description*",
                  rows: "1",
                  "auto-grow": "",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.loading || _vm.fetchingSpaces,
                  outlined: "",
                },
                model: {
                  value: _vm.spaceDescription,
                  callback: function ($$v) {
                    _vm.spaceDescription = $$v
                  },
                  expression: "spaceDescription",
                },
              }),
              !_vm.sidToClone
                ? _c(
                    "v-row",
                    { staticClass: "mb-4", attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { cols: "1" } }, [
                        _c("span", { staticClass: "subtitle-1" }, [
                          _vm._v("Visibility"),
                        ]),
                      ]),
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c(
                                "span",
                                { staticClass: "ml-1 font-weight-medium" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.selectedSpaceInformation.label)
                                  ),
                                ]
                              ),
                              _c("v-switch", {
                                staticClass: "my-0 ml-5",
                                attrs: {
                                  disabled: _vm.loading || _vm.fetchingSpaces,
                                  "hide-details": "",
                                  dense: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "caption" },
                                            [_vm._v("More options")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  311866477
                                ),
                                model: {
                                  value: _vm.showVisibilityOptions,
                                  callback: function ($$v) {
                                    _vm.showVisibilityOptions = $$v
                                  },
                                  expression: "showVisibilityOptions",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.showVisibilityOptions
                            ? _c(
                                "v-radio-group",
                                {
                                  attrs: { ripple: false },
                                  model: {
                                    value: _vm.spaceVisibility,
                                    callback: function ($$v) {
                                      _vm.spaceVisibility = $$v
                                    },
                                    expression: "spaceVisibility",
                                  },
                                },
                                _vm._l(
                                  _vm.spaceVisibilityTypes,
                                  function (visibilityType, index) {
                                    return _c(
                                      "div",
                                      { key: index },
                                      [
                                        _c("v-radio", {
                                          staticClass: "mt-3 caption",
                                          attrs: {
                                            color: "primary",
                                            value: visibilityType.label,
                                            label: visibilityType.label,
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.spaceVisibility ===
                                              visibilityType.label
                                                ? "caption secondary--text"
                                                : "caption",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  visibilityType.description
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              this.newSpaceType === this.spaceTypes.EDUCATION_SPACE
                ? _c(
                    "div",
                    { staticClass: "d-flex flex-column" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            attrs: {
                                              readonly: "",
                                              label: "Space archival date",
                                              rules: [_vm.rules.nonEmpty],
                                              required: "",
                                              outlined: "",
                                            },
                                            model: {
                                              value: _vm.spaceArchiveByDate,
                                              callback: function ($$v) {
                                                _vm.spaceArchiveByDate = $$v
                                              },
                                              expression: "spaceArchiveByDate",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1754048315
                          ),
                          model: {
                            value: _vm.spaceArchiveDateMenu,
                            callback: function ($$v) {
                              _vm.spaceArchiveDateMenu = $$v
                            },
                            expression: "spaceArchiveDateMenu",
                          },
                        },
                        [
                          _c("v-date-picker", {
                            on: {
                              input: function ($event) {
                                _vm.spaceArchiveDateMenu = false
                              },
                            },
                            model: {
                              value: _vm.spaceArchiveByDate,
                              callback: function ($$v) {
                                _vm.spaceArchiveByDate = $$v
                              },
                              expression: "spaceArchiveByDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-switch",
                    {
                      staticClass: "my-0 ml-5",
                      attrs: {
                        disabled: _vm.loading || _vm.fetchingSpaces,
                        color: _vm.showCloneOptions ? "success" : "default",
                        inset: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticClass: "caption text-body-2" },
                                [_vm._v("Create space based on a template")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.showCloneOptions,
                        callback: function ($$v) {
                          _vm.showCloneOptions = $$v
                        },
                        expression: "showCloneOptions",
                      },
                    },
                    [
                      _c("span", { staticClass: "ml-1 font-weight-medium" }, [
                        _vm._v("Create space based on a template"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-radio-group",
                {
                  attrs: { row: "", id: "cloneRadio" },
                  model: {
                    value: _vm.cloneRadio,
                    callback: function ($$v) {
                      _vm.cloneRadio = $$v
                    },
                    expression: "cloneRadio",
                  },
                },
                [
                  _vm.showCloneOptions
                    ? _c(
                        "v-row",
                        { staticStyle: { "justify-content": "space-around" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ma-3",
                                  attrs: {
                                    elevation:
                                      _vm.cloneRadio == "space" ? 8 : 1,
                                    outlined: _vm.cloneRadio == "space",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-radio", {
                                        attrs: { value: "space" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("v-autocomplete", {
                                                    staticClass: "ml-2",
                                                    staticStyle: {
                                                      "z-index": "2",
                                                    },
                                                    attrs: {
                                                      clearable: "",
                                                      items:
                                                        _vm.spacesWithCurrentType,
                                                      disabled:
                                                        _vm.cloneRadio ==
                                                          "repository" ||
                                                        _vm.fetchingSpaces ||
                                                        _vm.loading,
                                                      loading:
                                                        _vm.fetchingSpaces,
                                                      "item-text":
                                                        "space_long_id",
                                                      "item-value": "sid",
                                                      placeholder:
                                                        "Clone an existing space",
                                                      outlined: "",
                                                      "hide-details": "",
                                                    },
                                                    model: {
                                                      value: _vm.sidToClone,
                                                      callback: function ($$v) {
                                                        _vm.sidToClone = $$v
                                                      },
                                                      expression: "sidToClone",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          690266260
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ma-3",
                                  attrs: {
                                    elevation:
                                      _vm.cloneRadio == "repository" ? 8 : 1,
                                    outlined: _vm.cloneRadio == "repository",
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-radio", {
                                        attrs: { value: "repository" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c("v-text-field", {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      label: _vm.sidToClone
                                                        ? "Github repositories are only available in empty spaces"
                                                        : "Clone a Github repository",
                                                      "hide-details": "",
                                                      disabled:
                                                        _vm.cloneRadio ==
                                                        "space",
                                                      rules: [
                                                        _vm.rules.isGithubURL,
                                                      ],
                                                      outlined: "",
                                                    },
                                                    model: {
                                                      value: _vm.githubURL,
                                                      callback: function ($$v) {
                                                        _vm.githubURL = $$v
                                                      },
                                                      expression: "githubURL",
                                                    },
                                                  }),
                                                  !_vm.githubURL
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text caption ml-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Use SSH (git@...) or HTTPS (http://...) URLs."
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.githubURL &&
                                                  !_vm.repoURLIsHTTPS
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text caption ml-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Set up your "
                                                          ),
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                target:
                                                                  "_blank",
                                                                href: "https://docs.nuvolos.cloud/faqs/faqs#is-git-supported",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Nuvolos SSH keys"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " before cloning via SSH. "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.githubURL &&
                                                  _vm.repoURLIsHTTPS
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "secondary--text caption",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " You can only initialize from public repositories via HTTPS. If you plan to push commits to the remote repository from Nuvolos, use an SSH URL instead for better experience. "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          1257965514
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _vm.showCloneOptions
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", xl: "6" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                type: "warning",
                                dense: "",
                                "colored-border": "",
                                border: "left",
                                elevation: "2",
                              },
                            },
                            [
                              _vm._v(
                                " Copying content to the new space will execute as a background task and may take up to 10 minutes. "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              !(_vm.sidToClone || _vm.githubURL)
                ? _c("v-checkbox", {
                    attrs: {
                      disabled:
                        _vm.loading ||
                        _vm.fetchingSpaces ||
                        _vm.showCloneOptions,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [_c("span", [_vm._v("Add README")])]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3575092475
                    ),
                    model: {
                      value: _vm.addReadme,
                      callback: function ($$v) {
                        _vm.addReadme = $$v
                      },
                      expression: "addReadme",
                    },
                  })
                : _vm._e(),
              _vm.addReadme && !(_vm.sidToClone || _vm.githubURL)
                ? _c(
                    "div",
                    [
                      _c("MarkdownEditor", {
                        attrs: {
                          placeholderText:
                            _vm.selectedSpaceInfo.readmePlaceholder,
                        },
                        on: {
                          newMarkdownContent: function ($event) {
                            _vm.readmeFileContent = $event.value
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-alert",
        {
          staticClass: "mt-4 mx-auto",
          attrs: {
            "max-width": "600",
            value: _vm.error,
            color: "error",
            icon: "warning",
            text: "",
          },
        },
        [
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.errorContent)),
            ]),
            _c(
              "span",
              [
                _vm._v(
                  " For more information on space creation issues, check the troubleshooting documentation: "
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "font-weight-bold",
                    attrs: {
                      small: "",
                      text: "",
                      color: "error",
                      href: "https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-create-a-space",
                      target: "_blank",
                    },
                  },
                  [_vm._v(" here ")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("v-card-actions", [
        _c("div", { staticClass: "d-flex flex-column align-center w-100" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    loading: _vm.loading || _vm.fetchingSpaces,
                    disabled: !_vm.valid || _vm.loading || _vm.fetchingSpaces,
                    color: "primary",
                    elevation: "0",
                  },
                  on: {
                    click: function ($event) {
                      _vm.sidToClone ? _vm.cloneSpace() : _vm.addSpace()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                  _vm._v(
                    " " + _vm._s(_vm.sidToClone ? "Clone" : "Add") + " Space "
                  ),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    to: {
                      name: "home-dashboard",
                      params: { oid: _vm.$route.params.oid },
                    },
                    color: "primary",
                    outlined: "",
                    text: "",
                    exact: "",
                    disabled: _vm.loading || _vm.fetchingSpaces,
                  },
                },
                [_vm._v(" cancel ")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }